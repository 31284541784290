import { defineStore } from "pinia";

export const useNotifications = defineStore("notifications", {
  state: () => ({
    notifications: [],
  }),

  actions: {
    success(headline, body, duration = 3000) {
      this.notify(headline, body, "success", duration);
    },

    error(headline, body, duration = 3000) {
      this.notify(headline, body, "error", duration);
    },

    notify(headline, body, level, duration = 3000) {
      const notification = {
        id: Math.random() * 1000000,
        headline,
        body,
        level,
      };

      this.notifications.push(notification);

      setTimeout(() => this.remove(notification), duration);
    },

    remove(notification) {
      this.notifications.splice(
        this.notifications.findIndex(
          (existing) => existing.id === notification.id,
        ),
        1,
      );
    },
  },
});
